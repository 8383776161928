
export default function SplashScreen(){
  return (
    <div id="pluswrap" style={{height:'100vh'}}>
    <div className="plus">
      </div>
      <lottie-player src="https://assets7.lottiefiles.com/packages/lf20_obgheauh.json"  background="transparent"  speed="1"  style={{width:"100%",height:"100%"}}  loop  autoplay></lottie-player>
      </div>)
return (<div id="pluswrap">
<div className="plus">
  <div className="spinner-border text-primary" role="status">
    <span className="sr-only">Loading...</span>
  </div>
</div>
</div>)
}