import { createSlice } from '@reduxjs/toolkit'

export const TablesSlice = createSlice({
  name: 'tables',
  initialState: {
    loading: true,
    error:false,
    data:[]
  },
  reducers: {
    TablesLoadSuccess: (state,action) => {
      state.loading = false;
      state.error = false;
      state.data = [...action.payload];
    },
    TablesLoadError: (state,action) => {
      state.loading = false;
      state.error = action.payload
    },
    TablesLoadRequest: (state) => {
      state.loading = true
    },
  },
})

// Action creators are generated for each case reducer function
export const { TablesLoadSuccess, TablesLoadError, TablesLoadRequest } = TablesSlice.actions

export default TablesSlice.reducer