import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter, useRouteMatch } from "react-router-dom";
import ReactFlagsSelect from 'react-flags-select';
import { calculateOrderPrice, exportLogo, scaleImage, siparisDurumText } from "../provider/functions";
import moment from "moment";
import { useEffect, useState } from "react";
import { auth, firestore, getDocs } from "../provider/firebase";
import { collection, doc, getDoc, where,query, limit,onSnapshot, orderBy } from "firebase/firestore";
export default withRouter(ProfilePage)
export  function ProfilePage({history}) {
  const serverReducer = useSelector((state) => state.server.data);
  const language = useSelector(state=>state.language.data);
  const dispatch = useDispatch();
  const host = serverReducer.host;
  const[ docs,setdocs] = useState([]);
  let match = useRouteMatch();
    useEffect(()=>{
       
        // getDoc(doc(firestore,"orders/o1666775666544")).then(v=>console.log(v.data()))
        const q  = query(collection(firestore,"/orders/"),where("uid","==",auth.currentUser.uid),orderBy("created","desc"),limit(10))
        const subsribe = onSnapshot(q,docs=>setdocs(docs.docs.map(v=>({...v.data(),id:v.id}))));
        return subsribe;
    },[])
  return (
    <div className="rp-app rp-search">
    <div className="container-fluid">
      <div className="rp-page-body">
      <div className="rp-page-header" style={{backgroundImage:"linear-gradient(205deg, "+serverReducer.bgcolor2+", "+serverReducer.bgcolor1+")"}}>
              <div className="rp-hh-content flex-between">
                <div className="area">
                  <a onClick={history.goBack} className="link">
                    <i className="fa fa-arrow-left" />
                  </a>
                </div>
                <div className="area">
                  <div className="logo">
                    <img
                      src={
                        serverReducer.image
                          ? serverReducer.image
                          : "../"+exportLogo(serverReducer.host)
                      }
                      alt="Logo"
                    />
                  
                  </div>
                </div>
                <div className="area" style={{minWidth:40}}>
                  <a className="link" onClick={()=>{
                    auth.signOut().then(()=>history.goBack())
                  }}>
                  <i class="fas fa-sign-out-alt"></i>
                  </a>
                </div>
              </div>
       </div>
       <div style={{height:40}} />
     
      <div className="rp-see-all flex-between">
        <h2 className="title">{language.gecmis_siparisler}</h2>
        {/* <a  className="link">See All</a> */}
      </div>
      {docs.map(v=>v.packet).filter(v=>v).map((v,k)=><a  className="link-unstyled" key={k}>
        <div className="rp-product-box2 flex-start">
          {/* <div className="img"><img src="assets/img/kebab.png" alt="Image" /></div> */}
          <div className="content" style={{width:"100%"}}>
            {/* <h2 className="title">{siparisDurumText(v.status)}</h2> */}
            <div className="text" style={{fontWeight:"bold",textAlign:"left"}}>{moment(v.created?.time).format("YYYY-MM-DD HH:mm")}</div>
                  <p className="text">{v.orders?.map(v=><div>{v.quantity+"x "+v.product?.title}</div>)}</p>
                  <div className="price" style={{color:'orange'}}>{v.total} {serverReducer.currency} </div>
                
                  {v.paymentMethod ? <div className="badge bg-dark">{v.paymentMethod} </div> :""}
                  {v.status=="Error" ? <div className="badge bg-danger">{v.status} </div> :""}
          </div>
        </div>
      </a>)}
     
      </div>
    </div>
  </div>
  
  );
}
