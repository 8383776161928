import { createSlice } from '@reduxjs/toolkit'

export const CategoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    loading: true,
    error:false,
    data:[]
  },
  reducers: {
    CategoriesLoadSuccess: (state,action) => {
      state.loading = false;
      state.error = false;
      state.data = [...action.payload].map((value,index)=>({...value,index}));
    },
    CategoriesLoadError: (state,action) => {
      state.loading = false;
      state.error = action.payload
    },
    CategoriesLoadRequest: (state) => {
      state.loading = true
    },
  },
})

// Action creators are generated for each case reducer function
export const { CategoriesLoadSuccess, CategoriesLoadError, CategoriesLoadRequest } = CategoriesSlice.actions

export default CategoriesSlice.reducer