import Swal from "sweetalert2";
import store from "../redux/store";
export function exportLogo(host=""){
    if(host.includes("plusqr.kz") || host.includes("simfony.kz")){
      return "https://simfony.kz/image/logo-clr.png"
    }else if(host.includes("recpos")){
      return "https://recpos.az/app_logo.png"
    }
    return "../../logo.png"
  }
export function calculateOrderPrice(order, q) {
    const { quantity, product, options = [], extra } = order;
    const { price } = product;
    const optionsprice = optionsPrice(options)
    let fiyat = parseFloat(q || quantity) * parseFloat(parseFloat(price) + parseFloat(optionsprice))
    if (extra) {
        fiyat += parseFloat(extra);
    }
    return parseFloat(fiyat.toFixed(2));
}
export function calculateKuver(kuver=0,orders=[]) {
  if(!kuver){
    return 0;
  }
  const total = parsePrice(orders.map(v=>calculateOrderPrice(v)).reduce((a,b)=>a+b,0));
    if(!String(kuver).includes("%")){
      return parsePrice(String(kuver).replace(/[^\d.-]+/g, ''));
    }
    return yuzdeIslemleri.yuzde(total,String(kuver).replace(/[^\d.-]+/g, ''));
}
export function optionsPrice(options = []) {
    return options.reduce((a, b) => a.concat(b.data), []).filter(v => v.checked).map(v => parseFloat(v.price)).reduce((a, b) => a + b, 0)
}

export  function isLocal(){
    return getHostName()=="localhost" || getHostName()=="menu-online";
}
export function getHostName(){
    const split = window.location.hostname.split(".").filter(v=>v!="www" && v!="tr");
    if(split.length>2){
      return split[1]
    }
    return split[0];
}
export const randomColors = ["hsla(18,70%,80%,1)","hsla(253,70%,80%,1)","hsla(300,70%,80%,1)","hsla(129,70%,80%,1)","hsla(292,70%,80%,1)","hsla(37,70%,80%,1)","hsla(356,70%,80%,1)","hsla(334,70%,80%,1)","hsla(319,70%,80%,1)","hsla(159,70%,80%,1)","hsla(330,70%,80%,1)","hsla(165,70%,80%,1)","hsla(29,70%,80%,1)","hsla(213,70%,80%,1)","hsla(316,70%,80%,1)","hsla(312,70%,80%,1)","hsla(29,70%,80%,1)","hsla(239,70%,80%,1)","hsla(146,70%,80%,1)","hsla(265,70%,80%,1)","hsla(353,70%,80%,1)","hsla(235,70%,80%,1)","hsla(2,70%,80%,1)","hsla(96,70%,80%,1)","hsla(51,70%,80%,1)","hsla(197,70%,80%,1)","hsla(79,70%,80%,1)","hsla(103,70%,80%,1)","hsla(195,70%,80%,1)","hsla(156,70%,80%,1)","hsla(42,70%,80%,1)","hsla(317,70%,80%,1)","hsla(199,70%,80%,1)","hsla(66,70%,80%,1)","hsla(40,70%,80%,1)","hsla(170,70%,80%,1)","hsla(124,70%,80%,1)","hsla(251,70%,80%,1)","hsla(346,70%,80%,1)","hsla(256,70%,80%,1)","hsla(237,70%,80%,1)","hsla(182,70%,80%,1)","hsla(171,70%,80%,1)","hsla(238,70%,80%,1)","hsla(141,70%,80%,1)","hsla(113,70%,80%,1)","hsla(306,70%,80%,1)","hsla(199,70%,80%,1)","hsla(97,70%,80%,1)","hsla(28,70%,80%,1)"]
export const paraBirimleri = [
    {label:"Dollar ($)",value:"USD"},
    {label:"Pound (£)",value:"GBP"},
    {label:"Euro (€)",value:"EUR"},
    {label:"Türk lirası (₺)",value:"TRY"},
    {label:"Manat (₼)",value:"AZN"},
];
export const currencyChar = {
    TL:"₺",
    TRY:"₺",
    EUR:"€",
    USD:"$",
    GBP:"£",
    AZN:"₼",
}
export function randomHSL(){
    return "hsla(" + ~~(360 * Math.random()) + "," +
                    "70%,"+
                    "80%,1)"
}
export const parsePrice=(value)=>{
    const pp = (value+"").toString()
    const tonokta= pp.split(",").join(".")
    return parseFloat(parseFloat(tonokta).toFixed(2))
}
export const openUrl = (url)=>{
    if(window.launchURL){
        return window.launchURL.postMessage(url);
    }
      window.open(url, '_blank');
}
export const randomColorsGradient =randomColors.map(v=>"linear-gradient(180deg, "+v+" 0%, rgba(240,240,240,1) 100%)")
export function somethingWentWrong(title="Hata!",text="Birşeyler ters gidiyor!",type="error"){
    return Swal.fire(title,text,type);
}
export function successMessage(title="Başarılı!",text="İşlem başarı",type="success"){
    return Swal.fire(title,text,type);
}
export function playAudio(txt){
    if(window.play && window.isAndroid && window.isIOS){
        window.play.postMessage(txt);
      }else if(window.play && window.isElectron){
        window.play(txt);
      }else{
          
          new Audio("https://restomenum.com/audio/"+txt).play().catch(console.log)
      }
}
export function deleteAlert(title,text,cancel,confirm,icon="warning"){
    return Swal.fire({
        title: title,
        text: text,
        icon,
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        cancelButtonText:cancel,
        confirmButtonText: confirm
      }).then((result) => result.isConfirmed)
}
export function inputAlert(title,cancel,confirm,inputPlaceholder="",inputType="text"){
    return new Promise((resolve,reject)=>Swal.fire({
        title: title,
        input: inputType,
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: confirm,
        cancelButtonText:cancel,
        showLoaderOnConfirm: true,
        inputPlaceholder
       
      }).then((result) => {
        result.isConfirmed && resolve(result.value);
      }))
}
export function noReport(payments,propsPayments){
    const filter = propsPayments.filter(v=>v.noreport).map(v=>v.id)
    const data = payments.filter(v=>filter.indexOf(v.id)!=-1).map(v=>v.price).reduce((a,b)=>a+b,0);
    return data;
}
export function countryToLanguage(country){
 const data = {
     "US":"EN",
     "TR":"TR"
 }
 return data[country] || country
}
export const countries = ["TR", "US"];
export const countriesLabels = {"US": "English","TR": "Türkçe"};
export const getOrDefault = (name,def) =>{
    const item = localStorage.getItem(name);
    if(item && item!=null){
      return JSON.parse(item);
    }
    return def;
  }
export function numberToCurrency(number,currency){
    return parseFloat(number || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')+" "+currency
}
export function yuzdeBul(...args){
    const total = Math.max(...args)
    const number = Math.min(...args);
    if(number==0){
        return 0;
    }
    const sonuc = Math.ceil(parseInt(number)/(parseInt(total)/100))
    return (sonuc)
}


export function scaleImage(src = "", w = 200) {
  if (!src) return src;
  if (src.indexOf("cloudinary") != -1) {
      return src.replace("upload/", "upload/w_" + w + ",q_auto:eco/q_auto/").replace("png", "jpg")
  }
  if(src.includes("amazonaws") && src.includes("restomenum") && src.includes("original")){
      if(w==800) w = "original";
      // const aws = src.slice(0,src.length-"original.jpeg".length)+w+".jpeg";
      return src.replace("original",w);
  }
  return src;
}
export const allAuthority = [
    "editCategorie",
    "editPayments",
    "editUser",
    "editCustomer",
    "reOpen",
    "editDesing",
    "addOrder",
    "showReport",
    "addPayment",
    "editPayment",
    "addDelivery",
    "addPrepare",
    "gelirGider",
    "editPrinter",
    "editProduct",
    "cancelOrder",
    "paketkabul",
    "kurye",
    "isletmeYonetimi"
  ];
 export function fixAllEnt(values){

    const getir = fixEntegrasyonReports(values,"getir")
    const yemeksepeti = fixEntegrasyonReports(values,"yemeksepeti")
    const trendyol = fixEntegrasyonReports(values,"trendyol")
    return [getir,yemeksepeti,trendyol,...values].filter(v=>!isNaN(v.price) && v.price!=0)
  }
 export function fixEntegrasyonReports(values,title){
    const filters = values.filter(v=>(v.title+"").toLowerCase().startsWith((title+"").toLowerCase()));
    const getirsToplam = filters.reduce((a,b)=>a+b.price,0)
    return {
      id:title,
      title:("="+title).toUpperCase(),
      price:getirsToplam
    }
  }

 export function siparisDurumText(status){
     const getLang = store.getState().language.data;
    const durumlar = {
        "Rejected":<span style={{color:'red'}}><i class="fa fa-ban" style={{marginRight:5}}></i>{getLang.ayarlar_entegrasyon_siparis_durumu_iptal}</span>,
        "none":<span style={{color:'orange',paddingRight:5}}><i class="fa fa-clock"  style={{marginRight:5}}></i>{getLang.ayarlar_entegrasyon_siparis_durumu_onay_bekliyor}</span>,
        "Approved":<span style={{color:'orange',paddingRight:5}}><i class="fa fa-check"  style={{marginRight:5}}></i>{getLang.ayarlar_entegrasyon_siparis_durumu_hazirlaniyor}</span>,
        "Ready":<span style={{color:'green',paddingRight:5}}><i class="fa fa-check"  style={{marginRight:5}}></i>{getLang.ayarlar_entegrasyon_siparis_durumu_hazir}</span>,
        "OnDelivery":<span style={{color:'green',paddingRight:5}}><i class="fa fa-truck"  style={{marginRight:5}}></i>{getLang.ayarlar_entegrasyon_siparis_durumu_yolda}</span>,
        "Delivered":<span style={{color:'green',paddingRight:5}}><i class="fa fa-handshake"  style={{marginRight:5}}></i>{getLang.ayarlar_entegrasyon_siparis_durumu_teslim_edildi}</span>
    }
    return durumlar[status] || getLang.ayarlar_entegrasyon_siparis_durumu_onay_bekliyor;
  }
  export const yuzdeIslemleri = {
    topla:function(sayi, yuzde_deger){
      var yuzdemiz = (sayi * yuzde_deger) / 100;
      return sayi + yuzdemiz;
    },
    cikar:function(sayi, yuzde_deger){
      var yuzdemiz = (sayi * yuzde_deger) / 100;
      return sayi - yuzdemiz;
    },
    bol:function(sayi, yuzde_deger){
      var yuzdemiz = (sayi * yuzde_deger) / 100;
      return sayi / yuzdemiz;
    },
    carp:function(sayi, yuzde_deger){
      var yuzdemiz = (sayi * yuzde_deger) / 100;
      return sayi * yuzdemiz;
    },
    fark:function(sayi, yuzde_deger){
      var yuzdemiz = (sayi * yuzde_deger) / 100;
      return sayi - yuzdemiz;
    },
    yuzde:function(sayi, yuzde_deger){
      var yuzdemiz = (sayi * yuzde_deger) / 100;
      return yuzdemiz;
    },
    hesapla:function(sayi, yuzde_deger){
      var yuzdemiz = (sayi * yuzde_deger) / 100;
      return parseFloat(yuzdemiz).toFixed(2);
    },
    kdvekle: function(tutar, oran){
      var kdv = tutar * (oran / 100);
      var ytutar = tutar + kdv;
      return parseFloat(ytutar).toFixed(2);
    },
    kdvcikar: function(tutar, oran){
      var ytutar = tutar / ( 1 + ( oran / 100 ) );
      return parseFloat(ytutar).toFixed(2);
    }
  }