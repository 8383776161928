import { useDispatch, useSelector } from "react-redux";
import { useState,useEffect, useRef, useMemo } from "react";
import { withRouter, useRouteMatch, Link,useLocation } from "react-router-dom";
import BottomNavigation from "../components/BottomNavigation";
import Image from "../components/Image";
import _ from "lodash";
import { exportLogo, scaleImage } from "../provider/functions";
import { ServerLoadSuccess } from "../redux";
import { MotionLeftToRight, ScroolMotionDiv, VariantChildDiv, VariantContainerDiv } from "../components/motion/AllMotions";
export default withRouter(CategoryPage);
var scrollY = 0;
function CategoryPage({ history }) {
    let match = useRouteMatch();
    const location = useLocation()
    const [scroolY,setScroolY] = useState(0);
    
    const serverReducer = useSelector((state) => state.server.data);
    const products = useSelector((state) => [...state.products.data]);
    const mostSeller = useSelector((state) => [...state.products.mostSeller]);
    const categories = useSelector((state) => [...state.categories.data].filter(v=>!v.mother).sort((a,b)=>a.rank-b.rank));
    const subCategoriesReducer = useSelector((state) => [...state.categories.data].filter(v=>v.mother).sort((a,b)=>a.rank-b.rank));
    const [selectedCategory,setselectedCategory] = useState("")
    const languageRedux = useSelector((state) => state.language);
    const language = languageRedux.selected
    const categoriesReducer = useMemo(()=>[mostSeller.length>0? {title:String(languageRedux.data?.cok_satanlar).toUpperCase(),id:"mostseller"}:false,...categories].filter(v=>v?.id),[categories,mostSeller])
    const catRef = useRef([]);
    const catMenuRef = useRef([]);
    const _scrool = useRef(null);
    const dispatch = useDispatch(); 
    const menuRef = useRef();
    const scrollHandler = () => {
      clearTimeout(_scrool.current);
      _scrool.current = setTimeout(() => {
      
        const start = Array.from(document.getElementsByClassName("mothers")).map((v,i)=>v.offsetTop-(i==0?136:0))
        const ustMenuStart = Array.from(document.getElementsByClassName("ustmenu")).map((v,i)=>v.offsetLeft)
        const findSelected = start.filter((v,i)=>window.pageYOffset + window.innerHeight >= v).pop()
        if(findSelected){
          const index = start.indexOf(findSelected)
          if(index >-1)setselectedCategory(categoriesReducer[index].id)
          const sonraki = ustMenuStart[index-1] ? index-1:index;
          document.getElementById("ustmenu").scrollTo ({left:ustMenuStart[sonraki],top:0,behavior: 'smooth'});
        }
      }, 100);
      if(window.pageYOffset>136)
      dispatch(ServerLoadSuccess({scroolY:window.pageYOffset}))
      // const [last] = findSelected.reverse();
      // if(last){
      //    setselectedCategory(last.id)
      //    const _findIndex = categoriesReducer.findIndex(v=>v.id==last.id)
      //    _findIndex!=-1 && catMenuRef.current[_findIndex].scrollIntoView({ behavior: 'smooth', block: 'start' })
        
      // }
    }
    const productId = location.state ? location.state?.productId : "";
    const id = location.state ? location.state?.id : "";
    useEffect(() => {
      if(productId){
        setTimeout(() => {
          var rect = document.getElementById(productId).getBoundingClientRect()
          var y = rect.y + window.globalThis.scrollY-(146*2)
          window.scrollTo(0,y)
        }, 500);
      }else if(id){
        // const _findIndex = [...categoriesReducer].findIndex(v=>v.id==id)
        setselectedCategory(id)
        setTimeout(() => {
          scroolToCategory(id);
        }, 500);
      }else if(serverReducer.scroolY){
        window.scrollTo(0,serverReducer.scroolY)
      }
      window.addEventListener('scroll', scrollHandler, { passive: true });
      return () => {
          window.removeEventListener('scroll', scrollHandler);

          clearTimeout(_scrool.current);
      };
    }, [])
    function scroolToCategory(id){
      setselectedCategory(id)
      // const cikar = [...categoriesReducer][0].id == id ? 0 : 136;
      window.scrollTo(0,document.getElementById(id)?.offsetTop-145)
    }
  return (
    <div className="rp-app rp-home" id="tabcontent">
      <div className="container-fluid" >
        <div className="rp-page-body home" >
          <div className="rp-page-header home" style={{backgroundImage:"linear-gradient(205deg, "+serverReducer.bgcolor2+", "+serverReducer.bgcolor1+")"}}>
            <div className="rp-hh-content flex-between">
              <div className="area">
                <a onClick={()=>history.replace((match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/menu")} className="link">
                  <i className="fa fa-arrow-left" />
                </a>
              </div>
              <div className="area">
                <div className="logo">
                     <img src={serverReducer.image ? scaleImage(serverReducer.image) :("../"+exportLogo(serverReducer.host))} alt="Logo" />
                </div>
              </div>
              <div className="area" style={{minWidth:40}}>
                 {/* <a  className="link">
                  <i className="fa fa-bell" /> 
                </a>  */}
              </div>
            </div>
          </div>
           <ul className="rp-home-header-nav rp-home-nav nav nav-pills" ref={menuRef} id="ustmenu">
            
            {categoriesReducer.filter(v=>!v.mother).map((category,index)=><li className="nav-item ustmenu" key={index} id={"ustmenu"+category.id}>
              <button ref={c=>catMenuRef.current[index]=c}
                 onClick={(e)=>{
                  scroolToCategory(category.id)
                 }}
                className={selectedCategory==category.id ? "nav-link active" :"nav-link"}
              >
               {String((category.languages && category.languages[language]) ?  category.languages[language].title : (category.title)).toUpperCase()}
              </button>
            </li>)}
           
          </ul>
          {/* <h1 className="rp-home-title mt-4">
              {serverReducer.title}
            <img src="../../assets/img/hand.png" alt="Hand" />
          </h1> */}
          {/* <a  className="rp-home-location flex-start link-unstyled">
            <div className="icon">
              <i className="fa fa-map-marker-alt" />
            </div>
            <div className="content">
              <span className="title">Address</span>
              <span className="info text-green">{serverReducer.adress}</span>
            </div>
          </a> */}
          <div className="tab-content" style={{marginTop:5}} >
            <div
              className="tab-pane fade show active" >
              <div className="rp-see-all flex-between" >
                {/* <h2 className="title">Ürünler</h2> */}
                {/* <a  className="link">
                  Tümünü Gör
                </a> */}
              </div>
             
              {categoriesReducer.map((category,index)=>{
                if(category.id=="mostseller"){
                  return <div className="row mothers" id={"mostseller"} style={{paddingBottom:0}}>
                  <div className={"rp-see-all flex-between catitem-1"} >
                                <h1 className="title" style={{textAlign:"center",width:"100%",borderBottom:"2px solid "+serverReducer.textColor,borderTop:"2px solid "+serverReducer.textColor,fontFamily:"'Exo 2', sans-serif",color:serverReducer.textColor,fontWeight:'bold',fontStyle:"italic" ,fontSize:25}}> {String(languageRedux.data?.cok_satanlar).toUpperCase()}</h1>
                                {/* <a href="#" class="link">Hepsi</a> */}
                   </div>
                    { mostSeller.map((product,index)=><RenderProduct product={product} match={match} key={index}/>)}
                  </div> 
                }
              const productsFilter = products.filter(v=>v.category==category.id).sort((a,b)=>a.rank-b.rank)
              const subCategories = subCategoriesReducer.filter(v=>v.mother==category.id).map((value)=>({
                ...value,
                products:products.filter(v=>v.category==value.id).sort((a,b)=>a.rank-b.rank)
              }));
              return <VariantContainerDiv className="row mothers" key={index} id={category.id} style={{paddingBottom:(index==categoriesReducer.length-1)?0:136}}>
              <MotionLeftToRight className={"rp-see-all flex-between catitem"+index} ref={c=>catRef.current[category.index]=c}>
                            <h1 className="title" style={{textAlign:"center",width:"100%",borderBottom:"2px solid "+serverReducer.textColor,borderTop:"2px solid "+serverReducer.textColor,fontFamily:"'Exo 2', sans-serif",color:serverReducer.textColor,fontWeight:'bold',fontStyle:"italic" ,fontSize:25}}>{(category.languages && category.languages[language]) ?  category.languages[language].title : (category.title)}</h1>
                            {/* <a href="#" class="link">Hepsi</a> */}
               </MotionLeftToRight>
                { productsFilter.map((product,index)=><MotionLeftToRight key={index}><RenderProduct product={product} selected={productId==product.id} match={match} key={index}/></MotionLeftToRight>)}
               {subCategories.map((category,i)=>
                <div className="row" key={category.id} id={category.id} style={{paddingBottom:index==0?0:10}}>
              <div className={"rp-see-all flex-between catitem"+index} ref={c=>catRef.current[index]=c}>
                            <h2 className="title" style={{width:"100%",fontFamily:"'Exo 2', sans-serif",borderBottom:"2px solid "+serverReducer.textColor,borderTop:"2px solid "+serverReducer.textColor,color:serverReducer.textColor,fontSize:23}}>-{(category.languages && category.languages[language]) ?  category.languages[language].title : (category.title)}</h2>
                            {/* <a href="#" class="link">Hepsi</a> */}
               </div>
               {category.products.map((product,j)=><MotionLeftToRight key={j}><RenderProduct product={product} selected={productId==product.id} match={match} index={j}/></MotionLeftToRight>)}
                </div>)}
              </VariantContainerDiv>})}
            </div>
            
            <div className="action-margin" />
          </div>
        </div>
        <BottomNavigation />
      </div>
    </div>
  );
}

function RenderProduct({product,match,selected}){
  const getLang = useSelector((state) => state.language.data);
  const language = useSelector((state) => state.language.selected);
  const serverReducer = useSelector((state) => state.server.data);
  const desc = (product.languages && product.languages[language]) ?  product.languages[language].description : (product.qrDesc || " "); 
  return <Link to={serverReducer.noDetail ? window.location: ((match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/product/"+product.id)} className="col-12 col-md-6 col-lg-3">
    <div className="rp-product-box3 flex-start" id={product.id} style={{backgroundColor:serverReducer.backgroundColor,border:selected ? "5px solid green":serverReducer.backgroundColor=="white"?"white":("1px solid "+serverReducer.textColor)}}>
      <div className="img">
        <Image src={scaleImage(product.image)} alt="Image Not Found" />
      </div>
      <div className="content">
       <h2 className="price" style={{color:serverReducer.textColor}}> {(product.languages && product.languages[language]) ?  product.languages[language].title : (product.qrTitle || product.title)} </h2>
       
       {String(desc).length>1 ? <p style={{color:serverReducer.textColor}}>{desc} </p>:""}
        <span className="name" style={{color:serverReducer.textColor}}>{product.price} <span style={{fontSize:7}}>{serverReducer.currency}</span></span>
       {product.mostSeller ? <span class="badge bg-dark" style={{margin:2}}>{getLang.en_cok_satanlardan}</span> :""}
      </div>
    </div>
  </Link>
}