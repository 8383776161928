import { withRouter } from "react-router-dom";
import { useSelector ,useDispatch} from "react-redux";
import { useHistory } from "react-router";
import { useState } from "react";
import { addToCart } from "../redux/cartSlice";
import ProductDetail from "./ProductDetails";
import _ from "lodash";
import { toast } from "react-toastify";
import Style from 'style-it';
import { MotionImage, ScroolMotionDiv } from "../components/motion/AllMotions";
import { useEffect } from "react";
export default withRouter(ProductPage);
function ProductPage(props) {
  const { id,table,server } = props.match.params;
  const product = useSelector((state) =>
    state.products.data.find((v) => v.id == id)
  );
  const isWebStore = table=="webstore" ||table=="online";
  const serverReducer = useSelector((state) => state.server.data);
  const languageReducer = useSelector((state) => state.language);
  const language = languageReducer.data;
  const selected = languageReducer.selected
  const dispatch = useDispatch();
  const {currency,qrSiparis,textColor,buttonColor} = useSelector((state) =>state.server.data);
  const [qt, setqt] = useState(1)
  const [showModal, setshowModal] = useState(false)
  const { title, price, options, image, quantity,category,stok,stokluSatis } = product;
  const [note, setnote] = useState("");
  const [optionsData, setoptionsData] = useState([...options]);
  const optionsPrice = optionsData.reduce((a,b)=>a.concat(b.data),[]).filter(v=>v.checked).map(v=>parseFloat( v.price)).reduce((a,b)=>a+b,0)
  const productPrice =parseFloat((parseFloat(price)+parseFloat(optionsPrice))*qt).toFixed(2)
  function checkBox(i, ii) {
    let myoptions = JSON.parse(JSON.stringify([...optionsData].filter(v=>!v.hideQr)));
    const ayar = myoptions[i].multiple;
    const max = myoptions[i].max || ((ayar == "multiple" || ayar=="yes" || ayar==true)?99:1);
    myoptions[i].data[ii].checked = !myoptions[i].data[ii].checked;
    //const min = myoptions[i].min || 0;
    const howManyChecked = [...myoptions[i].data].filter(v=>v.checked).length;
    if(max==1){
      myoptions[i].data = myoptions[i].data.map((v,i2)=>({...v,checked:i2!=ii?false:!!v.checked}))
      setoptionsData(myoptions);
    }
    if(howManyChecked>max){
      toast.clearWaitingQueue();
      return toast.error(language.add_to_cart_order_options_max_limit,{
        toastId: String(i)+String(ii),
        duration:2000,
        position: 'bottom-center',
      })
    }    
    setoptionsData(myoptions);
  }

  function addCart() {
    const findNotPicked = [...optionsData].filter(v=>!v.hideQr).find(value=>{
      const checked = value.data.filter(v=>v.checked).length
      if(value.min>checked) return true;
      if(value.max<checked) return true;
      return false;
    })
    if(findNotPicked){
      const checked = findNotPicked.data.filter(v=>v.checked).length
      if(findNotPicked.min>checked) toast.error(language.add_to_cart_order_options_required)
      if(findNotPicked.max<checked) toast.error(language.add_to_cart_order_options_max_limit)
      return ;
    }
    dispatch(
      addToCart({product,quantity:qt,options:optionsData,note})
    );
    toast.success(((product.languages && product.languages[selected]) ?  product.languages[selected].title : (product.qrTitle || product.title)) + " "+language.sepete_ekle_success, {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
   
    props.history.replace((server?"/"+server:"")+"/"+table+"/categories/")
    // history.replace((match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/categories/",{id:category})
  }
  function plusOne(){
    const arttir = parseInt(qt) + 1;
    if(stokluSatis && arttir>stok){
      setqt(stok);
      return false;
    }
    setqt(arttir);
  }
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
  <div className="rp-app rp-details ">
    <div className="container-fluid ">
      <div className="rp-page-head rp-detail-head">
        <a onClick={()=>
          showModal ? setshowModal(false) : props.history.replace((server?"/"+server:"")+"/"+table+"/categories/",{productId:id})
        } className="back" style={{position:'fixed'}}><i className="fa fa-arrow-left" /></a>
        <a  className="heart active"><i className="fa fa-heart" /></a>
      </div>
      <div className="rp-detail-img">
      {product.image &&<ScroolMotionDiv className="owl-carousel owl-theme" id="detail-images" >
          <div className="item">
            <MotionImage src={product.image} alt="Image Not Found" />
          </div>
      
        </ScroolMotionDiv>}
      </div>
     {qrSiparis && <div className="rp-detail-number">
        <div className="number-input">
          <button onClick={()=>qt>1 ? setqt(parseInt(qt)-1):null} />
          <input  className="quantity"
                min={1}
                name="quantity"
                value={qt+""}
                onChange={(e)=>setqt(e.target.value)}
                type="number"
                required />
          <button onClick={()=>setqt(parseInt(qt)+1)} className="plus" />
        </div>
      </div>}
      {/* <div className="rp-detail-mini">
        <div className="row">
          <div className="col"><span className="stars"><i className="fa fa-star" /> 5.0</span></div>
          <div className="col"><span className="min"><i className="fa fa-tachometer-alt" /> Hızlı Servis</span></div>
          <div className="col"><span className="shipping"><i className="fa fa-dollar-sign" /> Önerilenler</span></div>
        </div>
      </div> */}
      <ScroolMotionDiv className="rp-detail-info bg-light card" style={{paddingRight:10,paddingLeft:10,borderRadius:10}} initial={{ opacity: 0,x:-50 }}
   whileInView={{ opacity: 1,x:0 }}>
        <h2 className="name" style={{fontWeight:'bold',color:"black",marginTop:30}}>{(product.languages && product.languages[selected]) ?  product.languages[selected].title : (product.qrTitle || product.title)}</h2>
        <p className="text" style={{color:"black"}}> {(product.languages && product.languages[selected]) ?  product.languages[selected].description : (product.qrDesc || " ")}</p>
      {optionsData.filter(v=>!v.hideQr).map((option,key)=>
        <div className="row" key={key}>
         {Style.it(`.hrWithTitle {
          position: relative;
          font-size: 20px;
          z-index: 1;
          overflow: hidden;
          text-align: center;
          font-weight: bold;

      }
      .hrWithTitle:before, .hrWithTitle:after {
          position: absolute;
          top: 51%;
          overflow: hidden;
          width: 50%;
          height: 1px;
          content: '\a0';
          background-color: black !important;
      }
      .hrWithTitle:before {
          margin-left: -50%;
          text-align: right;
      }
      `, <h6 className="hrWithTitle" style={{color:"black"}}> {option.title}</h6>)}
          {option.data.map((op,keyy)=><div className="option col-md-6 col-lg-4 col-sm-12" key={keyy} ><a className="form-check mb-2 mt-2" key={keyy}    onClick={()=>checkBox(key,keyy)}>
            <input
              className="form-check-input check-input-black nodisabledCheckbox"
              style={{color:"black"}}
              type="checkbox"
              checked={op.checked}
              disabled
            />
            <label className="form-check-label nodisabledCheckbox" style={{display:'block',color:"black"}}>
              {_.capitalize( String(op.title))} (+{op.price} {currency})
            </label>
          </a></div>)}
        </div>
        )}
        {(qrSiparis || isWebStore) &&  <>
          {Style.it(`.hrWithTitle {
          position: relative;
          font-size: 20px;
          z-index: 1;
          overflow: hidden;
          text-align: center;
          font-weight: bold;

      }
      .hrWithTitle:before, .hrWithTitle:after {
          position: absolute;
          top: 51%;
          overflow: hidden;
          width: 50%;
          height: 1px;
          content: '\a0';
          background-color: black !important;
      }
      .hrWithTitle:before {
          margin-left: -50%;
          text-align: right;
      }
      `, <h6 className="hrWithTitle mt-3 mb-3" style={{color:"black"}}> {language.siparis_notu}</h6>)}
         
          <textarea rows={2} className="form-control" value={note} onChange={(val)=>setnote(val.target.value)}/>
        </>}
        <div style={{height:35}}/>
      </ScroolMotionDiv>
      
      {/* <div className="rp-detail-brand flex-between">
        <div className="left flex-start">
          <div className="icon"><img src="assets/img/burger.png" alt="Brand" /></div>
          <div className="content">
            <span className="name">Kawsar Food</span>
            <span className="desc">1.6 Km from you</span>
          </div>
        </div>
        <div className="right">
          <div className="stars">
            <i className="fa fa-star" />
            <i className="fa fa-star" />
            <i className="fa fa-star" />
            <i className="fa fa-star" />
            <i className="fa fa-star gray" />
          </div>
        </div>
      </div> */}
      <div className="action-margin" />
      {(qrSiparis || isWebStore) && <div className="rp-product-modal content-btn">
        <div className="number-input">
          <button  onClick={() => (qt > 1 ? setqt(parseInt(qt) - 1) : null)} />
          <input  value={qt + ""}
              onChange={(e) => (stokluSatis ? (e.target.value>stok ? setqt(stok): setqt(e.target.value)):setqt(e.target.value)) }
              type="number" />
          <button  onClick={() => plusOne()} className="plus" />
        </div>
        <button onClick={addCart} className="btn btn-dark"   style={{color:"white"}}> {language.sepete_ekle} ({productPrice} {currency})</button>
      </div>}
    </div>
  </div>
  <div style={{height:100}}/>

</div>

  )
  
}
