import { createSlice } from '@reduxjs/toolkit'

export const PaymentsSlice = createSlice({
  name: 'payments',
  initialState: {
    loading: true,
    error:false,
    data:[]
  },
  reducers: {
    PaymentsLoadSuccess: (state,action) => {
      state.loading = false;
      state.error = false;
      state.data = [...action.payload];
    },
    PaymentsLoadError: (state,action) => {
      state.loading = false;
      state.error = action.payload
    },
    PaymentsLoadRequest: (state) => {
      state.loading = true
    },
  },
})

// Action creators are generated for each case reducer function
export const { PaymentsLoadSuccess, PaymentsLoadError, PaymentsLoadRequest } = PaymentsSlice.actions

export default PaymentsSlice.reducer