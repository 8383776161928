import { withRouter, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect,useRef } from "react";
//import PhoneInput from "react-phone-input-2";
import axios from "axios";
import { flushCart } from "../redux/cartSlice";
//import Swal from "sweetalert2";
import { auth, callFunction } from "../provider/firebase";
//import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import Swal from "sweetalert2";
import {CreatableSelectBox} from "../components/MySelectBox";
import { exportLogo, getHostName } from "../provider/functions";

export default withRouter(PayCart);
function PayCart({ history }) {
  const dispatch = useDispatch();
  let match = useRouteMatch();
  //const [step, setstep] = useState(1);
  const language = useSelector((state) => state.language.data);
  const [loading, setloading] = useState(false);
  const [adsoyad, setadsoyad] = useState("");
  const [masano, setmasano] = useState(match.params.table=="undefined" ? "" : match.params.table);
  //const [telefon, settelefon] = useState("");
  //const [verifycode, setverifycode] = useState("");
  //const [sharenumber, setsharenumber] = useState(false);
  // const [adres, setadress] = useState("");
  //const [coords, setcoords] = useState(null);
  const serverReducer = useSelector((state) => state.server.data);
  const tables = useSelector((state) => state.tables.data);
  const cart = useSelector((state) => state.cart.data);
   const [ip, setIP] = useState('');
  function siparisGonder() {
    setloading(true);

    window.grecaptcha.ready(()=> window.grecaptcha.execute('6Len1_wcAAAAAOB6BUL98_yAvZFfB6ME6vbzmshF', {action: 'qrMenu'}).then(token=>
    callFunction("siparisGonder")({
      token,
      server: match.params.server ? match.params.server : getHostName(),
      cart:cart.map(value=>({...value,note:value.note +" -"+adsoyad})),
      name: adsoyad ?? masano,
      table: masano,
    }).then((res) => {
        if (res.data.error) {
          if(res.data.error=="recaptcha-error"){
            return Swal.fire("Recaptcha!", language.recaptcha, "error");
          }if (res.data.error == "server-not-found") {
            return Swal.fire("Hata!", language.restorant_not_found, "error");
          } else if (res.data.error == "cart-not-found") {
            history.goBack();
            return Swal.fire("Hata!", language.sepette_urun_yok, "error");
          } else if (res.data.error == "name-not-found") {
            return Swal.fire("Hata!", language.ad_zorunlu, "error");
          } else if (res.data.error == "table-not-found") {
            return Swal.fire("Hata!", language.masa_zorunlu, "error");
          }
        }
        if (res.data == true) {
          history.push((match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/categories")
          dispatch(flushCart());
          return Swal.fire(
            language.basarili,
            language.siparis_iletildi,
            "success"
          );
        }
        return Swal.fire(
          language.hata,
          language.birseyler_ters_gidiyor,
          "error"
        );
      })
      .catch((err) => {
        console.log(err)
        return Swal.fire( language.hata, language.baglanti_hatasi, "error");
      })
      .finally(() => setloading(false))
    ))
 
  }
  useEffect( ()=>{
    async function getIp(){
      try {
        const res = await axios.get('https://api64.ipify.org?format=json')
        setIP(res.data.ip)
      } catch (error) {
        
      }
    }
    getIp();
    },[])
  return (
    <div>
      <div className="rp-app rp-search">
        <div className="container-fluid">
          <div className="rp-page-body " style={{ marginBottom: 100 }}>
            <div className="rp-page-header" style={{backgroundImage:"linear-gradient(205deg, "+serverReducer.bgcolor2+", "+serverReducer.bgcolor1+")"}}>
              <div className="rp-hh-content flex-between">
                <div className="area">
                  <a onClick={history.goBack} className="link">
                    <i className="fa fa-arrow-left" />
                  </a>
                </div>
                <div className="area">
                  <div className="logo">
                    <img
                      src={
                        serverReducer.image
                          ? serverReducer.image
                          : "../"+exportLogo(serverReducer.host)
                      }
                      alt="Logo"
                    />
                  </div>
                </div>
                <div className="area" style={{minWidth:40}}>
                  {/* <a className="link">
                    <i className="fa fa-bell" />
                  </a> */}
                </div>
              </div>
            </div>
            <div style={{height:40}} />
            <form action="">
                   <div className="group">
                        <h2 className="rp-page-name-mini" style={{color:serverReducer.textColor}}>{language.ad}</h2>
                        <input type="email" className="form-control"   value={adsoyad}
                  onChange={(e) => setadsoyad(e.target.value)} />
                    </div>
                 
              {match.params.table=="undefined" && <><h2 className="rp-page-name-mini" style={{color:serverReducer.textColor}}>{language.masa_oda}</h2>
              <div className="mb-4">
              <select className="form-select" onChange={(e) => e.target.value && setmasano(e.target.value)}>
                <option value="">{language.selectbox_sec}</option>
                  {tables.map((v,i)=><option key={i} value={v}>{v}</option>)}
                </select>

                
              </div></>}
              <h2 className="rp-page-name-mini" style={{color:serverReducer.textColor}}>{language.ip_adres}</h2>
              <div className="mb-4">
                <input
                  className="form-control rp-input-text"
                  placeholder={language.ip_adres}
                  value={ip}
                  disabled
                  //onChange={(e) => setmasano(e.target.value)}
                ></input>
              </div>
            </form>

            <div className="rp-action-margin-cart" />
          </div>
        </div>
      </div>

      <div className="rp-action rp-action-cart">
        <button
          className="btn btn-dark btn-block"
          onClick={siparisGonder}
          disabled={loading}
        >
          {loading ? (
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            language.siparis_ver
          )}
        </button>
      </div>
    </div>
  );
}
