import { combineReducers, configureStore, createStore } from '@reduxjs/toolkit'
import { ProductsSlice,CategoriesSlice,PaymentsSlice,ServerSlice,CartSlice,TablesSlice,languageSlice } from './index'

export default createStore(combineReducers({
      products:ProductsSlice.reducer,
      payments:PaymentsSlice.reducer,
      server:ServerSlice.reducer,
      categories:CategoriesSlice.reducer,
      cart:CartSlice.reducer,
      tables:TablesSlice.reducer,
      language:languageSlice.reducer
  }
))