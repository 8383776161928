import {  useSelector } from "react-redux";
import Carousel from 'react-bootstrap/Carousel';
export default function Sliders(props){
    const sliders = useSelector((state) => state.server.data.sliders);
    if(Array.isArray(sliders) && [...sliders].filter(v=>v && v.length>0).length>0){
       return <Carousel className="mt-2" {...props}>
        {sliders.filter(v=>v).map((v,i)=><Carousel.Item key={i}>
            <img
              className="d-block w-100" style={{maxWidth:"100%",maxHeight:300,minHeight:200,objectFit:'cover'}}
              src={v}
            />
          </Carousel.Item>)}    
        </Carousel>
    } 
    return null;
}