import { createSlice } from '@reduxjs/toolkit'

export const ProductsSlice = createSlice({
  name: 'products',
  initialState: {
    loading: true,
    error:false,
    data:[],
    mostSeller:[]
  },
  reducers: {
    ProductLoadSuccess: (state,action) => {
      state.loading = false;
      state.error = false;
      state.data = [...action.payload];
    },
    MostSellerLoadSuccess: (state,action) => {
      state.loading = false;
      state.error = false;
      state.mostSeller = [...action.payload].filter(v=>v.image);
    },
    ProductLoadError: (state,action) => {
      state.loading = false;
      state.error = action.payload
    },
    ProductLoadRequest: (state) => {
      state.loading = true
    },
  },
})

// Action creators are generated for each case reducer function
export const { ProductLoadSuccess, ProductLoadError, ProductLoadRequest ,MostSellerLoadSuccess} = ProductsSlice.actions

export default ProductsSlice.reducer