
//import ProductMenu1 from "../components/ProductMenu1";
import { useSelector } from "react-redux";
import { useState } from "react";
import { withRouter, useRouteMatch } from "react-router-dom";
import BottomNavigation from "../components/BottomNavigation";
import { exportLogo, randomColors, scaleImage } from "../provider/functions";
import Sliders from "../components/Slider";
import { VariantChildDiv, VariantContainerDiv } from "../components/motion/AllMotions";
export default withRouter(HomePage);
function HomePage({ history }) {
  let match = useRouteMatch();
  const serverReducer = useSelector((state) => state.server.data);
  //const loading = useSelector((state) => state.server.loading);
  // const productsReducer = useSelector((state) =>
  //   state.products.data.filter((v) => v.active)
  // );
  const languageRedux = useSelector((state) => state.language);
  const mostSeller = useSelector((state) => state.products.mostSeller);
  const language = languageRedux.selected;
  const categoriesReducer = useSelector((state) => [...state.categories.data].filter(v=>!v.mother));

  //const [selectedCategorie, setselectedCategorie] = useState("all");
  return (
    <div className="rp-app rp-home">
      <div className="container-fluid">
        <div className="rp-page-body " style={{marginBottom:100}}>
          <div className="rp-page-header" style={{backgroundImage:"linear-gradient(205deg, "+serverReducer.bgcolor2+", "+serverReducer.bgcolor1+")"}}>
            <div className="rp-hh-content flex-between">
              <div className="area">
                <a onClick={()=>history.push((match.params.server?"/"+match.params.server:"")+"/"+match.params.table)} className="link">
                  <i className="fa fa-arrow-left" />
                </a>
              </div>
              <div className="area">
                <div className="logo">
                  <img src={serverReducer.image ? scaleImage(serverReducer.image) :exportLogo(serverReducer.host)} alt="Logo" />
                </div>
              </div>
              <div className="area" style={{minWidth:40}}>
                 {/* <a  className="link">
                   <i className="fa fa-bell" /> 
                </a>  */}
              </div>
            </div>
          </div>
          <VariantContainerDiv className="row">
           <Sliders style={{marginBottom:10,marginTop:10}}/>
          { mostSeller.length>0 ? <div className="col-6 col-md-4 col-lg-3 col-xl-2" >
              <a onClick={()=>history.push((match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/categories/")} className="link-unstyled">
                <div className="rp-category-box">
                  <img src={scaleImage(mostSeller[0].image)} style={{backgroundColor:'black'}} />
                  <div className="content">
                    <h2 className="name">  ⭐ {String(languageRedux.data?.cok_satanlar).toUpperCase()} ⭐</h2>
                  </div>
                </div>
              </a>
            </div>:""}
           
            {categoriesReducer.map((category,index)=><VariantChildDiv className="col-6 col-md-4 col-lg-3 col-xl-2" key={index}>
              <a onClick={()=>history.push((match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/categories/",category)} className="link-unstyled">
                <div className="rp-category-box">
                  <img src={scaleImage(category.image)} style={{backgroundColor:category.image?'white':randomColors[index]}} />
                  <div className="content">
                    <h2 className="name">  {(category.languages && category.languages[language]) ?  category.languages[language].title : (category.title)}</h2>
                  </div>
                </div>
              </a>
            </VariantChildDiv>)}
          
          </VariantContainerDiv>
        </div>
        <BottomNavigation />
      </div>
    

    </div>
  );
}