import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter, useRouteMatch,useHistory } from "react-router-dom";
import ReactFlagsSelect from 'react-flags-select';
import { exportLogo, scaleImage, siparisDurumText } from "../provider/functions";
import { changeLanguage } from "../redux";
import { useEffect, useState } from "react";
import { auth, firestore, getDocs } from "../provider/firebase";

import { collection, doc, getDoc, where,query, limit,onSnapshot, orderBy } from "firebase/firestore";
import moment from "moment";
import Sliders from "../components/Slider";
import { ScroolMotionDiv } from "../components/motion/AllMotions";
export default function WelcomePage() {
  const serverReducer = useSelector((state) => state.server.data);
  const {selected,data,countries,keys} = useSelector(state=>state.language);
  const dispatch = useDispatch();
  const garsonCagirVarmi = (serverReducer.garsonCagir || []).length>0
  const host = serverReducer.host;
  let match = useRouteMatch();
  const table = (match.params.table+"").toLowerCase();
  const [customLabels,setcustomLabels] = useState({})
  const [codes,setcodes] = useState([])
  const [lastOrder,setLastOrder] = useState({})
  const history = useHistory()
  useEffect(()=>{
    var empty = {
      "AR":"Arabic"
    };
    var arrCodes = []
    countries.filter(v=>v.label && v.flag).sort((a,b)=>a.label>b.label?1:-1).map(value=>{
      empty[value.flag] = value.label;
      if(serverReducer.languages.includes(String(value.dilkodu).toUpperCase()) || serverReducer.languages.includes(value.flag) ){
        arrCodes.push(value.flag)
      }
    })
  
    setcodes(arrCodes)
    setcustomLabels(empty);

    if(auth.currentUser){
       const q = query(collection(firestore,"/orders/"),where("uid","==",auth.currentUser.uid),where("server.id","==",serverReducer.id),orderBy("created","desc"),limit(1))
       getDocs(q).then(docs=>{
        if(!docs.empty){
          const last = docs.docs[0].data().packet;
           setLastOrder({...last,orders:last.orders.filter(v=>v.product?.id!="teslimat")});
        }
       })
    }
},[])

  return (
    <div className="rp-app rp-first">
    <ScroolMotionDiv className="container-fluid" style={{backgroundImage:serverReducer.homeImage ?"url("+serverReducer.homeImage+")" :"",height:"100vh",backgroundPosition:'center',backgroundRepeat: "no-repeat",backgroundSize:'cover'}} onClick={(e)=>{
      if(serverReducer.homeImage) history.push((match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/menu")
    }}>
      <div className="rp-page-body rp-page-menu general" >
      <div className="rp-social-buttons">
        {serverReducer.facebook && <a href={serverReducer.facebook} target={"_blank"} className="link fb"><i className="fab fa-facebook-f"></i></a>}
        {serverReducer.twitter && <a href={serverReducer.twitter}  target={"_blank"} className="link tw"><i className="fab fa-twitter"></i></a>}
        {serverReducer.instagram && <a href={serverReducer.instagram}  target={"_blank"} className="link ig"><i className="fab fa-instagram"></i></a>}
        {serverReducer.whatsapp && <a href={serverReducer.whatsapp}  target={"_blank"} className="link " style={{backgroundColor:'green'}}><i className="fab fa-whatsapp"></i></a>}
        {serverReducer.website && <a href={serverReducer.website}  target={"_blank"} className="link "><i className="fab fa-internet-explorer"></i></a>}
        {serverReducer.telefon && <a href={"tel:"+serverReducer.telefon}  target={"_blank"} className="link "><i className="fas fa-phone"></i></a>}
        {serverReducer.harita && <a href={serverReducer.harita}  target={"_blank"} className="link "><i className="fas fa-map-marker-alt"></i></a>}
        
        </div>
        {!serverReducer.homeImage && <div className="rp-page-header rp-ph-general" style={{backgroundImage:"linear-gradient(205deg, "+serverReducer.bgcolor2+", "+serverReducer.bgcolor1+")",height:200}}/>}
        {!serverReducer.homeImage &&  <div className="rp-general-logo" >
          <img src={serverReducer.image ? scaleImage(serverReducer.image) :exportLogo(host)} alt="Logo" />
        </div>}
       
        {!serverReducer.homeImage && <div className="rp-info-general text-center">
        <div className="head">
            <h1 className="rp-general-title" style={{color:`${serverReducer.textColor}`}}>{serverReducer.qrTitle}</h1>
            <p className="rp-general-text"  style={{color:`${serverReducer.textColor}`}}>{serverReducer.qrDesc}</p>
          </div>
          <div className="rp-general-buttons" style={serverReducer.homeImage ? {position:'fixed',bottom:70,width:"90%"}:{}}>
            {/* <a  className="btn btn-primary btn-block">Kategoriler</a> */}
            <Link to={(match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/menu"} className="btn btn-dark btn-block" style={{borderColor:serverReducer.textColor,backgroundColor:serverReducer.buttonColor}}>{data.menu}</Link>
            <Link to={(match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/degerlendir"} className="btn btn-dark btn-block mt-3" style={{borderColor:serverReducer.textColor,backgroundColor:serverReducer.buttonColor}}><i className="fa fa-comment"></i> <span>{data.bizi_degerlendir}</span></Link>
            {(lastOrder?.orders ) && 
            <Link to={(match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/profile"}   className="link-unstyled" >
              <div className="rp-product-box2 flex-start">
                {/* <div className="img"><img src="assets/img/kebab.png" alt="Image" /></div> */}
                <div className="content" style={{width:"100%"}}>
                  {lastOrder.docNo?<h2 className=" badge bg-dark" style={{color:'white',width:"100%"}}>{data.docno || "Order number"}: {lastOrder.docNo}</h2>:""}
                  {/* <h2 className="title">{siparisDurumText(lastOrder.status)}</h2> */}
                  <span className="text" style={{fontWeight:"bold",textAlign:"right"}}>{moment(lastOrder.created?.time).format("YYYY-MM-DD HH:mm")}</span>
                  <p className="text">{lastOrder.orders?.map(v=><div>{v.quantity+"x "+v.product?.title}</div>)}</p>
                  <div className="price" style={{color:'orange'}}>{lastOrder.total} {serverReducer.currency}  </div>
                  {lastOrder.paymentMethod ? <div className="badge bg-dark">{lastOrder.paymentMethod} </div> :""}
                  {lastOrder.status=="Error" ? <div className="badge bg-danger">{lastOrder.status} </div> :""}
                </div>
              </div>
            </Link>
         }
            {(table!="undefined" && table!="webstore" && garsonCagirVarmi) && <Link className="btn btn-dark btn-block mt-3" to={(match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/garsoncagir"}>{data.garson_cagir}</Link>}
            <Sliders />
            {codes.length>1 ?<div className="mt-3">
                  <ReactFlagsSelect
                    selected={selected}
                    onSelect={code => {dispatch(changeLanguage(code))}}
                    placeholder={data.dil_secimi}
                    countries={codes}
                    customLabels={customLabels}
                    className="dilsecimi"
            
                  />
              </div>:""}
            {/* <a  className="btn btn-resto btn-block">Kategoriler</a> */}
            
          </div>
        </div>}
       {/* {!serverReducer.hideLogo && <div className="rp-bottom">
          <span className="powered-text">Powered by <img src={exportLogo(host)} alt="Logo" /></span>
        </div>} */}
      </div>
    </ScroolMotionDiv>
  </div>
  
  );
}
