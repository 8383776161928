import { createSlice } from "@reduxjs/toolkit";

export const CartSlice = createSlice({
  name: "cart",
  initialState: {
    data: [],
  },
  reducers: {
    addToCart: (state, action) => {
      state.data = [...state.data, action.payload];
    },
    removeFromCart: (state, action) => {
      state.data = [...state.data].filter((v, k) => k != action.payload);
    },
    editFromCart: (state, action) => {
      state.data = [...state.data].map((v, k) => {
        if (k == action.payload.index) {
          return Object.assign({},v,action.payload);
        }
        return v;
      }).filter(v=>v.quantity>0);
    },
    flushCart: (state, action) => {
      state.data = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { addToCart, removeFromCart, editFromCart,flushCart } = CartSlice.actions;

export default CartSlice.reducer;
