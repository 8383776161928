import { withRouter, useRouteMatch,useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect,useRef } from "react";
//import PhoneInput from "react-phone-input-2";
import PhoneInput from "react-phone-input-2";
import { flushCart } from "../redux/cartSlice";
import { auth, callFunction, firestore, messaging,app  } from "../provider/firebase";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import {  doc,getDoc } from "firebase/firestore"; 
import axios from "axios";
import moment, { lang } from 'moment';
import { calculateOrderPrice, exportLogo, getHostName, inputAlert, parsePrice, somethingWentWrong, successMessage } from "../provider/functions";
import { getToken,isSupported,getMessaging } from "firebase/messaging";
import MySelectBox from "../components/MySelectBox";
import MyTimePicker,{MyTimePicker2} from "../components/MyTimePicker";
import Swal from "sweetalert2";

export default withRouter(PaytrPay);
function PaytrPay({ history }) {
  const dispatch = useDispatch();
  let match = useRouteMatch();
  const {state} = useLocation();
  //const [step, setstep] = useState(1);
  const [loading, setloading] = useState(false);
  const [card_number, setcard_number] = useState("");
  const [paytr, setPaytr] = useState({
    merchant_id:"",
    merchant_oid:"",
    payment_type:"",
    email:"",
    payment_amount:"",
    test_mode:"1",
    merchant_ok_url:"https://restomenum.com",
    merchant_fail_url:"https://restomenum.com",
    user_name:"",
    user_address:"",
    user_phone:"",
    user_basket:"",
    debug_on:"",
    paytr_token:"",
    installment_count:"",
    cc_owner:"",
    card_number:card_number+"",
    expiry_month:"",
    expiry_year:"",
    cvv:"",
    store_card:true
  });

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const formRef = useRef(null);
  const language = useSelector((state) => state.language.data);
  const selectedLang = useSelector((state) => state.language.selected);
  const serverReducer = useSelector((state) => state.server.data);

  const currency = serverReducer.currency;
  const cart = useSelector((state) => state.cart.data);
  const toplam = parseFloat( parseFloat( cart.map(v=>calculateOrderPrice(v)).reduce((a, b) => a + b, 0)).toFixed(2))
  async function onSignInSubmit(){
    formRef.current?.submit();
  }

  useEffect(  ()=>{
    async function main(){
      setloading(true);
      const access_token = await  auth.currentUser?.getIdToken();
      callFunction("payWithPayTr")({
        access_token,
        server: match.params.server ? match.params.server : getHostName(),
        cart,
        table:match.params.table,
        name: state.adsoyad,
      }).then((res)=>{
        if(res?.data?.success){
          const data = (res?.data.data)
          setPaytr({...paytr,...data})
  
          if(state?.ctoken && state?.require_cvv=="0"){
            setTimeout(() => {
              formRef.current?.submit();
            }, 200);
          }
        }
        setloading(false)
      })
    }
    main();
  },[])
  return (
    <div>
       <form action="https://www.paytr.com/odeme" method="POST" ref={formRef}>
      <div className="rp-app rp-search">
        <div className="container-fluid">
          <div className="rp-page-body " style={{ marginBottom: 100 }}>
            <div className="rp-page-header" style={{backgroundImage:"linear-gradient(205deg, "+serverReducer.bgcolor2+", "+serverReducer.bgcolor1+")"}}>
              <div className="rp-hh-content flex-between">
                <div className="area">
                  <a onClick={history.goBack} className="link">
                    <i className="fa fa-arrow-left" />
                  </a>
                </div>
                <div className="area">
                  <div className="logo">
                    <img
                      src={
                        serverReducer.image
                          ? serverReducer.image
                          : "../"+exportLogo(serverReducer.host)
                      }
                      alt="Logo"
                    />
                  </div>
                </div>
                <div className="area" style={{minWidth:40}}>
                  {/* <a className="link">
                    <i className="fa fa-bell" />
                  </a> */}
                </div>
              </div>
            </div>

             
             {!state?.ctoken && <><h2 className="rp-page-name-mini" style={{color:serverReducer.textColor}}>Kart Üzerindeki Ad, Soyad</h2>
              <div className="mb-1">
                <input
                  className="form-control rp-input-text"
                  name="cc_owner"
                  value={paytr.cc_owner}
                  onChange={(e) => setPaytr({...paytr,cc_owner:e.target.value})}
                  onKeyDown={e=>{
                    if(e.key=="Enter")  ref1.current?.focus();
                  }}
                ></input>
              </div>
              <h2 className="rp-page-name-mini" style={{color:serverReducer.textColor}}>Kart Numarası</h2>
              <div className="mb-1">
                <input
                  type="text"
                
                  ref={ref1}
                  onKeyDown={e=>{
                    if(e.key=="Enter")  ref2.current?.focus();
                  }}
                  className="form-control rp-input-text"
                  value={paytr.card_number.replace(/\D/g,'')}
                  onChange={(e) => {
                    if(e.target.value==null || e.target.value==""){
                      return  setPaytr({...paytr,card_number:""})
                    }
                    const cardValue = String(e.target.value).replace(/\D/g, '').match(/(\d{1,4})(\d{0,4})(\d{0,4})(\d{0,4})/);
                    const lastValue = !cardValue[2]? cardValue[1]: `${cardValue[1]}-${cardValue[2]}${(`${cardValue[3] ? `-${cardValue[3]}` : ''}`)}${(`${cardValue[4] ? `-${cardValue[4]}` : ''}`)}`
                    setcard_number(e.target.value.replace(/\D/g,''));
                    setPaytr({...paytr,card_number:lastValue})
                  }}
                ></input>
              </div>
              <h2 className="rp-page-name-mini" style={{color:serverReducer.textColor}}>Kart Son Kullanma Tarihi</h2>
              <div style={{display:'flex',justifyContent:'space-evenly'}} >

              <div style={{marginRight:5}}>
                <input
                  className="form-control rp-input-text"
                  type="text"
                  maxLength={2}
                  ref={ref2}
                  name="expiry_month"
                  value={paytr.expiry_month}
                  onKeyDown={e=>{
                    if(e.key=="Enter")  ref3.current?.focus();
                  }}
                  onChange={(e) =>{
                    var value = String(e.target.value).replace(/[^\d.-]+/g, '')
                    setPaytr({...paytr,expiry_month:value})
                    if(value.length==2) ref3.current?.focus();
                  }}
                ></input>
              </div>
            
              <div style={{marginLeft:5}}>
                <input
                  className="form-control rp-input-text"
                  type="text"
                  maxLength={2}
                  ref={ref3}
                  name="expiry_year"
                  onKeyDown={e=>{
                    if(e.key=="Enter")  ref4.current?.focus();
                  }}
                  value={paytr.expiry_year}
                  onChange={(e) => setPaytr({...paytr,expiry_year:String(e.target.value).replace(/[^\d.-]+/g, '')})}
                ></input>
              </div>
              </div></>}
              {(state?.require_cvv == "1" || !state?.ctoken ) && <><h2 className="rp-page-name-mini" style={{color:serverReducer.textColor}}>CVV Güvenlik Kodu</h2>
              <div >
                <input
                  className="form-control rp-input-text"
                  value={paytr.cvv}
                  type="number"
                  ref={ref4}
                  name="cvv"
                  maxLength={3}
                  onChange={(e) => {
                    setPaytr({...paytr,cvv:String(e.target.value).replace(/[^\d.-]+/g, '')})
                  }}
                ></input>
              </div></>}
              {!state?.ctoken && <>
              <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}} >
              <h2 className="rp-page-name-mini" style={{color:serverReducer.textColor}}>Kartı Kaydet</h2>
         
                <div className="form-check form-switch">
               <input className="form-check-input" type="checkbox" checked={paytr.store_card}
               ref={(node) => {
                if (node) {
                  node.style.setProperty("border-radius", "5px", "important");
                }
              }} style={{minWidth:40,placeSelf:'center'}} onChange={e=>setPaytr({...paytr,store_card:!!e.target.checked})} />
                </div>
   
              </div>
              </>}
              <div className="os_kartlar_x3" >
                  <img
                  className="img-responsive tr_en_img_x"
                  src="https://www.paytr.com/img/odeme_sayfasi/os_kartlar_x3.svg?v3"
                  alt="Kart Güvenliği"
                  style={{ padding: "40px 0 10px 0" }}
                />
              </div>
                <>
              
                <input
                  type="hidden"
                  name="card_number"
                  value={card_number}
                />
                <input
                  type="hidden"
                  name="merchant_id"
                  value={paytr.merchant_id}
                />
                <input type="hidden" name="user_ip" value={paytr.user_ip} />

                <input
                  type="hidden"
                  name="merchant_oid"
                  value={paytr.merchant_oid}
                />
                <input type="hidden" name="email" value={paytr.email} />
                <input
                  type="hidden"
                  name="payment_type"
                  value={paytr.payment_type}
                />
                <input
                  type="hidden"
                  name="payment_amount"
                  value={paytr.payment_amount}
                />
                <input type="hidden" name="currency" value={paytr.currency}></input>
                <input
                  type="hidden"
                  name="test_mode"
                  value={paytr.test_mode}
                />
                <input type="hidden" name="non_3d" defaultValue={paytr.non_3d} />
                <input
                  type="hidden"
                  name="merchant_ok_url"
                  value={paytr.merchant_ok_url}
                />
                <input
                  type="hidden"
                  name="merchant_fail_url"
                  value={paytr.merchant_fail_url}
                />
                <input type="hidden" name="user_name"  value={paytr.user_name} />
                <input type="hidden" name="user_address"  value={paytr.user_address} />
                <input type="hidden" name="user_phone" value={paytr.user_phone} />
                <input
                  type="hidden"
                  name="user_basket"
                  value={paytr.user_basket}
                />
                <input type="hidden" name="debug_on"  value={paytr.debug_on} />
                <input type="hidden" name="client_lang" value={paytr.client_lang}></input>
                <input
                  type="hidden"
                  name="paytr_token"
                  value={paytr.paytr_token}
                />
               
                 <input
                  type="hidden"
                  name="non3d_test_failed"
                  value={paytr.non3d_test_failed}
                /> 
                <input
                  type="hidden"
                  name="installment_count"
                  value={paytr.installment_count}
                />
                 <input
                  type="hidden"
                  name="card_type"
                  value={paytr.card_type}
                />
                 <input type="hidden" name="no_installment"  value={paytr.no_installment} /> 
                <input type="hidden" name="max_installment"  value={paytr.max_installment} /> 
                <input type="hidden" name="lang"  value={"tr"} />
                {paytr.utoken ? <input type="hidden" name="utoken" value={paytr.utoken}/>:""}
                {state.ctoken ? <input type="hidden" name="ctoken" value={state.ctoken}/>:""}
                <input type="checkbox" style={{display:'none'}} name="store_card" checked={paytr.store_card ? "1":"0"}/> 
               
              </>

             
              {/* {bildirim.length<3 &&<button className="btn  btn-block" style={{backgroundColor:'black',color:'white',marginTop:20,marginBottom:20}} onClick={e=>{e.preventDefault();requestPushNotification()}}>
                 Sipariş Durumlarını Bildirim Olarak Al
              </button>} */}
            <div className="rp-action-margin-cart" />
            <div style={{height:100}}/>
          </div>
        </div>
      </div>

      <div className="rp-action rp-action-cart">
      <center style={{ paddingBottom: 20,display:'block' }}> 
       <div id="recaptcha-container" className="recaptcha-container"/>
            </center>
        
        <button
          className="btn btn-dark btn-block"
          onClick={onSignInSubmit}
          style={{backgroundColor:serverReducer.buttonColor,borderColor:serverReducer.buttonColor,color:"white"}}
          // disabled={ loading || cart.length==0}
        >
          {loading ? (
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            `Ödeme Yap ${toplam} ${currency}`
          )}
        </button>
      </div>
      </form>
    </div>
  );
}
