import { withRouter, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect,useRef } from "react";
//import PhoneInput from "react-phone-input-2";
// import StarRatingComponent from 'react-star-rating-component';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import { deleteAlert, exportLogo, getHostName, somethingWentWrong, successMessage } from "../provider/functions";
import { callFunction } from "../provider/firebase";
import {Radio,RadioGroup,FormControlLabel,FormControl,FormLabel} from '@mui/material';
import BottomNavigation from "../components/BottomNavigation";
export default withRouter(Degerlendirme);
function Degerlendirme({ history }) {
  const dispatch = useDispatch();
  let match = useRouteMatch();
  //const [step, setstep] = useState(1);
  const [loading, setloading] = useState(false);
  const [ilkdefa, setilkdefa] = useState(null);
  const [temizlik, settemizlik] = useState(4);
  const [genelpuan, setgenelpuan] = useState(4);
  const [ziyaretpuani, setziyaretpuani] = useState(4);
  const [yorum, setyorum] = useState("");
  const [adsoyad, setadsoyad] = useState("");
  const [telefon, settelefon] = useState("");
  const [kvkk, setkvkk] = useState(false);
  const [bilgipaylasim, setbilgipaylasim] = useState(false);
  const language = useSelector((state) => state.language.data);
  const selectedLang = useSelector((state) => state.language.selected);
  const serverReducer = useSelector((state) => state.server.data);
  
  function gonder(){
    
    if(ilkdefa==null){
      return deleteAlert(language.qr_degerlendir_1,"",language.hayir,language.evet,"info").then(res=>{
        setilkdefa(res);
      })
    }else if(adsoyad.length<3){
      return somethingWentWrong(language.invalid_name_text,"");
    }else if(localStorage.getItem("comment")){
      return successMessage(language.daha_once_yorum_yaptiniz,"")
    }
    setloading(true)
    window.grecaptcha.ready(()=> window.grecaptcha.execute('6Len1_wcAAAAAOB6BUL98_yAvZFfB6ME6vbzmshF', {action: 'degerlendir'}).then(token=>callFunction("degerlendir")({
      token,temizlik,genelpuan,ziyaretpuani,adsoyad,telefon,yorum,ilkdefa,  server: match.params.server ? match.params.server : getHostName(),
    }).then(()=>{
      successMessage(language.yorum_gonderildi,"")
      localStorage.setItem("comment",true);
      history.goBack();
    }).finally(()=>setloading(false))
    ))
  }
  return (
    <div>
      <div className="rp-app rp-search">
        <div className="container-fluid">
          <div className="rp-page-body " style={{ marginBottom: 100 }}>
            <div className="rp-page-header" style={{backgroundImage:"linear-gradient(205deg, "+serverReducer.bgcolor2+", "+serverReducer.bgcolor1+")"}}>
              <div className="rp-hh-content ">
               
                <div className="area">
                  <div className="logo" style={{textAlign:'center'}}>
                    <img
                      src={
                        serverReducer.image
                          ? serverReducer.image
                          : "../"+exportLogo(serverReducer.host)
                      }
                      alt="Logo"
                    />
                  </div>
                </div>
               
              </div>
            </div>
              <div>
              <div style={{height:50}}/> <center>
              <h2 style={{color:serverReducer.textColor}}>{language.qr_degerlendir_1}</h2>
           <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        style={{justifyContent:'center',color:serverReducer.textColor}}
        value={ilkdefa}
        
        onChange={(e)=>setilkdefa(e.target.value)}
      >
        <FormControlLabel value="true" control={<Radio />} style={{color:serverReducer.textColor}} label={language.evet} />
        <FormControlLabel value="false" control={<Radio />} style={{color:serverReducer.textColor}} label={language.hayir} />
 
      </RadioGroup>
            <hr/>
             <h2 style={{color:serverReducer.textColor}}>{language.qr_degerlendir_2}</h2>
              <Rating
                className={"Rating"}
                value={temizlik}
                size="large"
                onChange={(event, newValue) => {
                  settemizlik(newValue);
                }}
              />
             
              <hr/>
              <h2 style={{color:serverReducer.textColor}}>{language.qr_degerlendir_3}</h2>
              <Rating
                className={"Rating"}
                value={genelpuan}
                size="large"
                onChange={(event, newValue) => {
                  setgenelpuan(newValue);
                }}
              />
             
              <hr/>
              <h2 style={{color:serverReducer.textColor}}>{language.qr_degerlendir_4}</h2>
              <Rating
                className={"Rating"}
                value={ziyaretpuani}
                size="large"
                onChange={(event, newValue) => {
                  setziyaretpuani(newValue);
                }}
              />
             
              </center>
              <hr/>
              <h2 className="rp-page-name-mini" style={{color:serverReducer.textColor}}>{language.qr_degerlendir_5}</h2>
              <div className="mb-4">
                <textarea
                rows={4}
                  className="form-control rp-input-text"
                  placeholder="..."
                  value={yorum}
                  onChange={(e) => setyorum(e.target.value)}
                ></textarea>
              </div>
              <h2 className="rp-page-name-mini" style={{color:serverReducer.textColor}}>{language.ad}</h2>
              <div className="mb-4">
                <input
                  className="form-control rp-input-text"
                  placeholder="..."
                  value={adsoyad}
                  onChange={(e) => setadsoyad(e.target.value)}
                ></input>
              </div>
              <h2 className="rp-page-name-mini" style={{color:serverReducer.textColor}}>{language.telefon}</h2>
              <div className="mb-4">
                <input
                  className="form-control rp-input-text"
                  placeholder="..."
                  value={telefon}
                  onChange={(e) => settelefon(e.target.value)}
                ></input>
              </div>
           

            </div>
            <h2 className="rp-page-name-mini" style={{color:serverReducer.textColor}}>{language.kullanim_kosullari}</h2>
              <div className="mb-2">
                  <a className="d-flex align-items-center mb-3 form-control"   onClick={()=>setkvkk(kvkk==false)}>
                    <div style={{width: '40px', height: '25px'}}>
                    <input type="checkbox" style={{
                      width: 25,
                      height: 25,
                      marginRight: 15,
                      marginBottom: 2,
                    }}  checked={kvkk} onClick={() => setkvkk(kvkk==false)} />
                    </div>
                   <span >{language.kullanim_kosullari_kabul}</span> 
                  </a>
              </div>
              <div className="mb-4">
                  <a className="d-flex align-items-center mb-3 form-control"  onClick={()=>{setbilgipaylasim(bilgipaylasim==false)}}>
                    <div style={{width: '40px', height: '25px'}}>
                    <input type="checkbox" style={{
                      width: 25,
                      height: 25,
                      marginRight: 15,
                      marginBottom: 2,
                    }} checked={bilgipaylasim} onClick={() => setbilgipaylasim(bilgipaylasim==false)} />
                    </div>
                    <span >{language.kullanim_kosullari_bilgi_paylaşım.replace("${title}",serverReducer.title)}</span>
                  </a>
              </div>
           
            <button
          className="btn btn-dark btn-block"
          
          onClick={()=>gonder()}
          disabled={ loading || !bilgipaylasim || !kvkk }
        >
          {loading ? (
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            `${language.kaydet} `
          )}
        </button>
        <div style={{height:150}}/>
          </div>
        </div>
      </div>
        <BottomNavigation />

     
    </div>
  );
}
