import { createSlice } from '@reduxjs/toolkit'

export const ServerSlice = createSlice({
  name: 'Server',
  initialState: {
    loading: true,
    error:false,
    data:{ 
      currency: 'TRY',
       title: 'Demo Cafe', 
       image: null,
       bgcolor1: "#f27507",
       bgcolor2: "#f1cd21",
       backgroundColor: "white",
       buttonColor:"black",
       textColor: "black",
       garsonCagir: [],
       payments:[],
       paytr: false,
       qrDesc: "",
       qrSiparis: false,
       qrTitle: "",
      }
  },
  reducers: {
    ServerLoadSuccess: (state,action) => {
      state.loading = false;
      state.error = false;
      state.data = Object.assign({},state.data,action.payload);
    },
    ServerLoadError: (state,action) => {
      state.loading = false;
      state.error = action.payload;
    },
    ServerLoadRequest: (state) => {
      state.loading = true
    },
  },
})

// Action creators are generated for each case reducer function
export const { ServerLoadSuccess, ServerLoadError, ServerLoadRequest } = ServerSlice.actions

export default ServerSlice.reducer