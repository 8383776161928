import { createSlice,current } from '@reduxjs/toolkit'

import languagesJson  from './languages.json';
const getOrDefault = (name,def) =>{
  const item = localStorage.getItem(name);
  if(item && item!=null){
    return JSON.parse(item);
  }
  return def;
}
let selected = getOrDefault("language","EN")
export const languageSlice = createSlice({
  name: 'language',
  initialState: {
      selected:"EN",
      data:languagesJson.EN,
      keys:Object.keys(languagesJson),
      countries:Object.keys(languagesJson).map(v=>({code:languagesJson[v].ulkekodu,flag:languagesJson[v].flag,label:languagesJson[v].dil,dilkodu:languagesJson[v].dilkodu,value:v})).filter(v=>v.code),
  },
  reducers: {
    changeLanguage: (state,action) => {
      const selected = action.payload.toUpperCase();
      const index = Object.keys(languagesJson).indexOf(selected);
      const fc = [...current(state).countries].find(v=>v.value==selected || v.code==selected)
      console.log(selected)
      if(selected=="AR" || selected=="SA"){
        state.selected = "AR";
        localStorage.setItem("language",JSON.stringify("SA"))
        state.data = languagesJson.AR
      }else if(fc){
        state.selected = fc.code;
        localStorage.setItem("language",JSON.stringify(fc.value))
        state.data = languagesJson[fc.value]
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { changeLanguage} = languageSlice.actions

export default languageSlice.reducer