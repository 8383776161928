import { useDispatch, useSelector } from "react-redux";
import { useState,useEffect, useRef, useMemo } from "react";
import { withRouter, useRouteMatch, Link,useLocation } from "react-router-dom";
import BottomNavigation from "../components/BottomNavigation";
import Image from "../components/Image";
import _ from "lodash";
import { exportLogo, scaleImage } from "../provider/functions";
import { ServerLoadSuccess } from "../redux";
import { MotionLeftToRight, ScroolMotionDiv, VariantChildDiv, VariantContainerDiv } from "../components/motion/AllMotions";
export default withRouter(SearchPage);
var scrollY = 0;
function SearchPage({ history }) {
    let match = useRouteMatch();
    const location = useLocation()
    const [search,setSearch] = useState("");
    
    const serverReducer = useSelector((state) => state.server.data);
    const products = useSelector((state) => [...state.products.data]);
    const languageRedux = useSelector((state) => state.language);
    const language = languageRedux.selected
   const productsFilter = useMemo(()=> products.filter(v=>JSON.stringify(v).toLowerCase().includes(String(search).toLowerCase())),[search,products]);
  return (
    <div className="rp-app rp-home" id="tabcontent">
      <div className="container-fluid" >
        <div className="rp-page-body home" >
          <div className="rp-page-header home" style={{paddingBottom:30, backgroundImage:"linear-gradient(205deg, "+serverReducer.bgcolor2+", "+serverReducer.bgcolor1+")"}}>
            <div className="rp-hh-content flex-between">
              <div className="area">
                <a onClick={()=>history.replace((match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/menu")} className="link">
                  <i className="fa fa-arrow-left" />
                </a>
              </div>
              <div className="area">
                <div className="logo">
                     <img src={serverReducer.image ? scaleImage(serverReducer.image) :("../"+exportLogo(serverReducer.host))} alt="Logo" />
                </div>
              </div>
              <div className="area" style={{minWidth:40}}>
                 {/* <a  className="link">
                  <i className="fa fa-bell" /> 
                </a>  */}
              </div>
            </div>
            <div className="input-group" style={{marginTop:20}}>
             
              <input
                type="text"
                className="form-control"
                placeholder="..."
                onChange={(e)=>setSearch(e.target.value)}
              />
               <span className="input-group-text" id="basic-addon1" style={{backgroundColor:'white'}}>
                <i className="fa fa-search"/>
              </span>
            </div>

          </div>
           
          {/* <h1 className="rp-home-title mt-4">
              {serverReducer.title}
            <img src="../../assets/img/hand.png" alt="Hand" />
          </h1> */}
          {/* <a  className="rp-home-location flex-start link-unstyled">
            <div className="icon">
              <i className="fa fa-map-marker-alt" />
            </div>
            <div className="content">
              <span className="title">Address</span>
              <span className="info text-green">{serverReducer.adress}</span>
            </div>
          </a> */}
          <div className="tab-content" style={{marginTop:5}} >
            <div
              className="tab-pane fade show active" >
              <div className="rp-see-all flex-between" >
                {/* <h2 className="title">Ürünler</h2> */}
                {/* <a  className="link">
                  Tümünü Gör
                </a> */}
              </div>
             
               <VariantContainerDiv className="row mothers" >
                { productsFilter.map((product,index)=><MotionLeftToRight key={index}><RenderProduct product={product} match={match} key={index}/></MotionLeftToRight>)}
              </VariantContainerDiv>
            </div>
            
            <div className="action-margin" />
          </div>
        </div>
        <BottomNavigation />
      </div>
    </div>
  );
}

function RenderProduct({product,match}){
  const getLang = useSelector((state) => state.language.data);
  const language = useSelector((state) => state.language.selected);
  const serverReducer = useSelector((state) => state.server.data);
  const desc = (product.languages && product.languages[language]) ?  product.languages[language].description : (product.qrDesc || " "); 
  return <Link to={serverReducer.noDetail ? window.location: ((match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/product/"+product.id)} className="col-12 col-md-6 col-lg-3">
    <div className="rp-product-box3 flex-start" id={product.id} style={{backgroundColor:serverReducer.backgroundColor,border:serverReducer.backgroundColor=="white"?"white":("1px solid "+serverReducer.textColor)}}>
      <div className="img">
        <Image src={scaleImage(product.image)} alt="Image Not Found" />
      </div>
      <div className="content">
       <h2 className="price" style={{color:serverReducer.textColor}}>{(product.languages && product.languages[language]) ?  product.languages[language].title : (product.qrTitle || product.title)} </h2>
       
       {String(desc).length>1 ? <p style={{color:serverReducer.textColor}}>{desc} </p>:""}
        <span className="name" style={{color:serverReducer.textColor}}>{product.price} <span style={{fontSize:7}}>{serverReducer.currency}</span></span>
       {product.mostSeller ? <span class="badge bg-dark" style={{margin:2}}>{getLang.en_cok_satanlardan}</span> :""}
      </div>
    </div>
  </Link>
}