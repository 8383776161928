import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import AsyncSelect from 'react-select/async';
const styles = {
  valueContainer: (provided, state) => ({
    ...provided,
    'text-align': 'left;',
  }),
  option: (provided, state) => ({
    ...provided,
    'text-align': 'left;',
  }),
  
}
export default function MySelectBox(props){
    return (<Select styles={styles} {...props}  />)
}
export function CreatableSelectBox(props){
  return (
      <CreatableSelect
        styles={styles} {...props}
        isClearable
      />
  )
}
export function PromiseSelect(props){
  return (
    <AsyncSelect styles={styles} {...props} cacheOptions defaultOptions  />
  )
}